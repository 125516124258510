import { Theme } from "@mui/material";
import jellinekTheme from "../theme/jellinek-theme";
import trimbosTheme from "../theme/trimbos-theme";
import tactusTheme from "../theme/tactus-theme";
import brijderTheme from "../theme/brijder-theme";
import antesTheme from "../theme/antes-theme";
import dehoopTheme from "../theme/dehoop-theme";
import terwilleTheme from "../theme/terwille-theme";
import nkTheme from "../theme/nk-theme";
import vigoTheme from "../theme/vigo-theme";
import emergisTheme from "../theme/emergis-theme";
import vnnTheme from "../theme/vnn-theme";
import defaultTheme from "../theme/theme";

const jellinekHostname = "alcoholzelftest.jellinek.nl";
const trimbosHostname = "alcoholzelftest.alcoholinfo.nl";
const tactusHostname = "alcoholzelftest.tactus.nl";
const brijderHostname = "alcoholzelftest.brijder.nl"
const antesHostname = "alcoholzelftest.anteszorg.nl"
const dehoopHostname = "alcoholzelftest.dehoop.org"
const terwilleHostname = "alcoholzelftest.terwille.nl"
const askTerwilleHostname = "alcoholzelftest.ask-terwille.nl"
const nkHostname = "alcoholzelftest.novadic-kentron.nl"
const vigoHostname = "alcoholzelftest.vvgi.nl"
const emergisHostname = "alcoholzelftest.1nul1.nl"
const vnnHostname = "alcoholzelftest.vnn.nl"

const themeMap: Record<string, Theme> = {
  [jellinekHostname]: jellinekTheme,
  [trimbosHostname]: trimbosTheme,
  [tactusHostname]: tactusTheme,
  [brijderHostname]: brijderTheme,
  [antesHostname]: antesTheme,
  [dehoopHostname]: dehoopTheme,
  [terwilleHostname]: terwilleTheme,
  [askTerwilleHostname]: terwilleTheme,
  [nkHostname]: nkTheme,
  [vigoHostname]: vigoTheme,
  [emergisHostname]: emergisTheme,
  [vnnHostname]: vnnTheme,
}

const logoMap: Record<string, string> = {
  [jellinekHostname]: "/logo192_jellinek.png",
  [trimbosHostname]: "/logo_trimbos.webp",
  [tactusHostname]: "/logo_tactus.svg",
  [brijderHostname]: "/logo_brijder.png",
  [antesHostname]: "/logo_antes.png",
  [dehoopHostname]: "/logo_dehoop.png",
  [terwilleHostname]: "/logo_terwille.png",
  [askTerwilleHostname]: "/logo_ask_terwille.png",
  [nkHostname]: "/logo_nk.jpg",
  [vigoHostname]: "/logo_vigo.svg",
  [emergisHostname]: "/logo_emergis.svg",
  [vnnHostname]: "/logo_vnn.svg",
}

const faviconMap: Record<string, string> = {
  [jellinekHostname]: "/favicon_jellinek.png",
  [trimbosHostname]: "/favicon_trimbos.png",
  [tactusHostname]: "/logo_tactus.svg",
  [brijderHostname]: "/favicon_brijder.png",
  [antesHostname]: "/favicon_brijder.png",
  [dehoopHostname]: "/favicon_dehoop.png",
  [terwilleHostname]: "/favicon_terwille.png",
  [askTerwilleHostname]: "/favicon_terwille.png",
  [nkHostname]: "/favicon_nk.png",
  [vigoHostname]: "/favicon_vigo.ico",
  [emergisHostname]: "/favicon_emergis.png",
  [vnnHostname]: "/favicon_vnn.png",
};

export const theme = themeMap[window.location.host] || defaultTheme
export const logoReference = logoMap[window.location.host] || "/logo192.png";
export const faviconReference = faviconMap[window.location.host] || "/favicon.ico";

/////////// Helpers
export const isDefaultTheme = !themeMap[window.location.host]
export const isBrijderTheme = window.location.host === brijderHostname
